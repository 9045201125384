// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200;
}

.btn-outline-light{
  color: $gray-900;
}

// btn-soft-color

@each $color,
$value in $theme-colors {
    .btn-soft-#{$color} {
        background-color: rgba(($value), 0.25);
        border-color: rgba(($value), 0.25);
        color: $value;
    }
}
